/* eslint-disable no-unused-vars */

import { getLocalStorage, setLocalStorage } from '@shein/common-function'

import UserKey from '../UserKey'

// 记录返回的时间 20秒内不在更新返回值
let lastGetTime = 0

// 是否正在请求
let isProcessing = false

// 等待队列
const requestQueue = []

// 清空队列
const clearRequestQueue = (countryData) => {
  if (!requestQueue.length) {  
    isProcessing = false
    return false
  }

  requestQueue.forEach(fun => {
    fun(countryData)
  })
  requestQueue.splice(0, requestQueue.length)
  isProcessing = false
}

// 本地存储key
const LOCAL_STORAGE_KEY = 'ipCountry'

// 有效期3天
const COOKIE_MAX_AGE = 1000 * 3600 * 24 * 3

// 是否中东国家
const isArab = (country) => {
  return ['SA', 'AE', 'KW', 'IL', 'JO', 'QA', 'OM', 'AR'].indexOf(country.toUpperCase()) > -1
}

// 是否印度
const isIndia = (country) => {
  return ['IN', 'IND'].indexOf(country.toUpperCase()) > -1
}

const handleFormatCountryAbbr = (countryAbbr) => {
  // 转换后的值
  let formatCountryAbbr = countryAbbr
  if (isArab(countryAbbr)) {
    formatCountryAbbr = 'ar'
  } else if (isIndia(countryAbbr)) {
    formatCountryAbbr = 'in'
  }

  return formatCountryAbbr
}

class Country extends UserKey {
  static canHandle(key) {
    return key === 'Country'
  }

  // 避免页面生命周期内触发突然失效导致的副作用，如果剩余30分钟主动触发一次更新
  #checkRemainingTimeAndUpdate() {
    if(typeof window === 'undefined') return false
    
    const localDataString = window.localStorage.getItem(LOCAL_STORAGE_KEY)

    const localData = localDataString ? JSON.parse(localDataString) : {}

    if (localData?.end) {
      if (Date.now() > localData?.end - (1000 * 60 * 30)) {
        this.update()
      }
    }
  }

  /**
   * 获取本地存储的country信息
   * @returns {{
  *   countryId: String,
  *   countryAbbr: String
  *   formatCountryAbbr: String
  * }}
  */
  #getMemoryCountry() {
    const localCountryData = getLocalStorage(LOCAL_STORAGE_KEY)
  
    if (localCountryData?.countryId) {
      return localCountryData
    } else {
      return {}
    }
  }

  /**
   * 获取ip对应国家id以及国家简称
   * @param {{
  *   reset: Boolean
  * }} options 
  * @returns {Promise<{
  *   countryId: String,
  *   countryAbbr: String
  *   formatCountryAbbr: String
  * }>}
  */
  async get(options) {
    // 是否强制拿取最新数据
    const reset = options?.reset || false
    if (reset) {
      const countryData = await this.update()
      if (countryData.countryId) {
        return countryData
      }
    }

    // 优先取LocalStorage
    // 然后尝试取cookie
    // 没有则重新请求下游接口获取
    const localCountryData = this.#getMemoryCountry()
    if (localCountryData?.countryId) {
      this.#checkRemainingTimeAndUpdate()

      return localCountryData
    } else {
      const countryData = await this.update()

      return countryData
    }
  }

  getSync(options) {
    const localCountryData = this.#getMemoryCountry()
    if (localCountryData?.countryId) {
      this.#checkRemainingTimeAndUpdate()
    }

    return localCountryData
  }

  /**
   * 设置当前用户Ip对应国家信息
   * 外部不建议直接调用set,使用get传reset: true参数来客户端中间层同步强制刷新
   * @param {{
  *   countryId: String,
  *   countryAbbr: String,
  *   formatCountryAbbr: String
  * }} countryData 
  */
  set(countryData) {

    if (!countryData?.countryId || !countryData?.countryAbbr)  {
      console.error('missing parameter on set Country method')
      return false
    }

    setLocalStorage({ key: LOCAL_STORAGE_KEY, value: countryData, expire: COOKIE_MAX_AGE })
  }

  /**
   * 更新ip对应国家id以及国家简称，并返回最新数据
   * @returns {Promise<{
   *   countryId: String,
   *   countryAbbr: String,
   *   formatCountryAbbr: String
   * }>}
   */
  async update() {
    return new Promise((resolve, reject) => {

      // 20秒内使用不重新请求
      if (lastGetTime > 0 && Date.now() - lastGetTime < 20 * 1000) {
        resolve(this.#getMemoryCountry())

        return false
      }

      // 只允许一次请求通过，后续复用第一次请求结果
      if (isProcessing) {
        requestQueue.push((country) => {
          resolve(country)
        })
      } else {
        isProcessing = true

        const countryDataDefaultHandle = () => {
          const countryDataDefault = {
            countryId: '',
            countryAbbr: '',
            formatCountryAbbr: ''
          }

          resolve(countryDataDefault)

          clearRequestQueue(countryDataDefault)
        }

        this.getIpCountryApi().then((country) => {
          if (!country?.countryId) {
            countryDataDefaultHandle()
            return false
          }

          const formatCountryAbbr = handleFormatCountryAbbr(country.countryAbbr)
    
          const countryData = {
            countryId: country?.countryId,
            countryAbbr: country?.countryAbbr,
            formatCountryAbbr
          }
          
          this.set(countryData)

          if (countryData?.countryId) {
            lastGetTime = Date.now()
          }
    
          resolve(countryData)

          clearRequestQueue(countryData)
          
        }).catch((e) => {
          console.error('UserKey.Country.update.error', e)
          
          countryDataDefaultHandle()
        })
      }
    })
  }
}

export default Country

/* eslint-disable no-unused-vars */
/* eslint-disable @shein-aidc/common/notDirectUseCookie */
import UserKey from '../UserKey'
import { getCookie } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { setLocalStorage } from '@shein/common-function'

const { default_currency, currencies, lang } = gbCommonInfo
const COOKIE_KEY = 'currency'
const MAX_AGE = 1000 * 60 * 60 * 24 * 20  //20天

class Currency extends UserKey {
  static canHandle(key) {
    return key === 'currency'
  }

  get({onlyCookie = false} = {}) {
    const SUPPORT_CURRENCIES = Object.values(currencies || {})[0]
    const oldCookieName = `pwa_currency_${gbCommonInfo.lang}`

    //由于cookie可能是其它站点设置，这里的cookie值必须为支持值，
    let value = default_currency
    //由于这里可能比较早执行，还没有请求到服务，cookie还是没被服务端验证过
    let cookieValue = getCookie(COOKIE_KEY) || getCookie(oldCookieName)
    //如果只需要cookie值而不需要默认值
    if( onlyCookie ){
      return cookieValue
    }
    //cookie必须是当前站点支持值
    if( cookieValue && SUPPORT_CURRENCIES[cookieValue] ){
      value = cookieValue 
    }
    return value
  }
  async set(value, { params = {} } = {}) {
    Object.assign(params, {
      currency: value
    })

    //提供给用增游戏等其它pwa项目获取货币
    setLocalStorage({ key: COOKIE_KEY, value: value, expire: MAX_AGE })

    const res = await schttp({
      url: `/api/common/currency/update`,
      params
    })
    return { value, res }
  }
}

export default Currency

import schttp from 'public/src/services/schttp'
// 接口请求进行修改，获取数据
// 
export const getPersonalizedSwitchStatus = async () => {

  try {
    const result = await schttp({
      url: '/user/setting/personalized_switch',
      useBffApi: true
    })
  
    if (!result || result.code != 0) return null
    return result?.info?.personalizedSwitch || null
  } catch (error) {
    return null
  }
}

export const setPersonalizedSwitchStatus = async (params) => {
  try {
    const keys = Object.keys(params)
    if (!keys.length) return false
  
    const formData = new FormData()
    keys.forEach(key => {
      formData.append(key, params[key])
    })
  
    const data = await schttp({
      method: 'POST',
      url: '/user/setting/personalized_switch',
      useBffApi: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: formData
    })
    // success
    if (data.code == 0) return true
    return false 
    
  } catch (error) {
    return false
  }
}
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { formatAccountChannel } from './formatData.js'
daEventCenter.addSubscriber({ modulecode: '2-8' })

export const exposeRememberAccountPop = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'expose_remember_account_pop'
    },
  })
}

export const clickRememberAccountPopNo = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_remember_account_pop_no',
      beacon: true
    },
  })
}

export const clickRememberAccountPopYes = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_remember_account_pop_yes',
      beacon: true
    },
  })
}

export const clickSwitchAccount = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_switch_account'
    },
  })
}

export const exposeSwitchAccount = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'expose_switch_account'
    },
  })
}

export const exposeRetainRemovePop = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'expose_retain_remove_pop'
    },
  })
}

export const clickRetainRemovePopCancel = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_retain_remove_pop_cancel'
    },
  })
}

export const clickRetainRemovePopRemove = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_retain_remove_pop_remove'
    },
  })
}

export const clickRemoveDone = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_done'
    },
  })
}

export const clickRemoveAccount = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_remove_account'
    },
  })
}

export const clickRemoveLink = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_remove'
    },
  })
}

export const clickGoLoginRegisterPage = () => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_go_login_register_page'
    },
  })
}

export const clickAccount = ({ alias_type, is_remember }) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_account',
      account_channel: formatAccountChannel(alias_type),
      remember_type: is_remember == '1' ? 1 : 0
    },
  })
}

export const clickLoginSignin = ({ type, code }) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_login_signin',
      login_from: true,
      type: type == 1 ? 'email' : 'phone',
      status: code == 0 ? 'success' : 'failure',
      result_reason: code == 0 ? '-' : code
    },
  })
}

export const clickSigninThirdParty = ({ alias_type }) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_signin_third_party',
      login_from: true,
      third_party: formatAccountChannel(alias_type),
    },
  })
}

export const clickSigninThirdPartyResult = ({ alias_type, type = 'login' }) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'click_signin_third_party_result',
      login_from: true,
      third_party: formatAccountChannel(alias_type),
      type
    },
  })
}

export const exposeSingleAccount = ({ alias_type, is_remember }) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'expose_single_account',
      account_channel: formatAccountChannel(alias_type),
      remeber_type: is_remember == '1' ? 1 : 0
    },
  })
}


export const exposeMultiAccount = ({ alias_type, is_remember }) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'expose_account',
      account_channel: formatAccountChannel(alias_type),
      remeber_type: is_remember == '1' ? 1 : 0
    },
  })
}

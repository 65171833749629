import { getCookie, setCookie, removeCookie, getLocalStorage, setLocalStorage, removeLocalStorage } from '@shein/common-function'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { getPersonalizedSwitchStatus } from './query'

const COOKIE_KEY = 'uswitch'
const STORAGE_KEY = 'personalizedSwitch'
const HEADER_KEY = 'uberctx-personal-switch'

/**
 * 个性化推荐设置服务，没有请求逻辑，只有 cookie，localstorage 操作
 */
class Personalized {
  cookieMapArray = [
    ['s', 'search'], 
    ['r', 'recommend'], 
    ['u', 'userGrowth']
  ]

  /**
   * 获取当前主域名
   * @returns 
   */
  getCurMainDomain() {
    // us.shein.com => shein.com
    // us.shein.co => shein.co
    // m.shein.com => shein.com
    const domain = window.location.hostname
    const domainArr = domain.split('.')
    domainArr.shift()
    return domainArr.join('.')
  }

  // 落地页检查是否登出需要将未登陆时候的状态还原到之前设置
  // a.没有 mid，没有个性化 cookie，同步 localstorage 中的值设置一个新的个性化 cookie，当成未登陆之前的状态还原；
  // b.没有 mid，有个性化 cookie，不处理；
  // c.有 mid，没有个性化 cookie，不处理；
  // d.有 mid，有个性化 cookie，不处理；
  checkRecoverIsLogout() {
    try {
      // 1. 如果有已经登陆，不处理
      if (isLogin()) return
      // 2. 如果没有登陆，获取cookieId
      const personalConfig = this.getConfig()
      if (personalConfig) return
  
      // 3. 如果没有cookieId，获取localstorage
      this.setConfigFromStorage()
    } catch (error) {
      console.log('同步还原状态失败！！', error);
    }
  }

  /**
   * 从 localstorage 中获取个性化推荐配置并设置 cookie 上
   */
  setConfigFromStorage() {
    try {
      // 3. 如果没有cookieId，获取localstorage
      const storageConfig = getLocalStorage(STORAGE_KEY)
      if (storageConfig) {
        // 设置 cookieId
        this.setConfig(storageConfig)
      }
    } catch (error) {
      console.log('设置状态失败！！', error);
    }
  }

  /**
   * 删除个性化推荐配置 cookie
   */
  delConfig() {
    try {
      removeCookie({ 
        key: COOKIE_KEY,
        domain: this.getCurMainDomain(),
      })
    } catch (error) {
      console.log('删除cookieId或者personalizedSwitch失败!!!', error)
    }
  }

  /**
   * 从 cookie 中获取个性化推荐配置
   * @returns {} { search: 1, recommend: 0 }
   */
  getConfig() {
    try {
      const cookieValue = getCookie(COOKIE_KEY)
      // s-1.r-0.u-1
      if (cookieValue) {
        // [[r, 1],r, 0]]
        const cookieArray = decodeURIComponent(cookieValue).split('.').map(_ => _.split('-'))
        const result = this.cookieMapArray.reduce((acc, cur) => {
          const curClose = cookieArray.find(([key, value]) => key === cur[0] && value == 0)
          acc[cur[1]] = curClose ? 0 : 1
          return acc
        }, {})
        if (Object.keys(result).length) return result
      }
      return null
    } catch (error) {
      console.log('获取cookieId或者personalizedSwitch失败!!!', error)
      return null
    }
  }
  
  /**
   * 设置个性化推荐配置 —— cookie 和 localstorage
   * @param {*} config { s: 0, r: 0, p: 1 }
   * @param {*} isLogout 当前设置时候是否是非登陆态下 —— 只有在 setting 页面在才会传 true
   */
  setConfig(config, isLogout) {
    try {
      if (typeof config !== 'object') return
      const configEntries = Object.entries(config)
      // 1. 非登陆态下设置，存储到localstorage，等到登出恢复到 cookie
      if (isLogout) {
        // [r, null, p]
        const saveLocalKey = configEntries.map(([key, value]) => {
          if (!value) return key
          return null
        }).filter(Boolean)

        // 都改成默认开启状态，删掉 localstorage
        if (!saveLocalKey.length) {
          // 都开启的状态不存（默认开启）
          removeLocalStorage(STORAGE_KEY)
        } else {
          // 只存储关闭的状态
          // { s: false, r: false, p: false }
          const localConfig = saveLocalKey.reduce((acc, cur) => {
            acc[cur] = false
            return acc
          }, {})
          setLocalStorage({
            key: STORAGE_KEY,
            value: localConfig,
            expire: 1000 * 60 * 60 * 24 * 14, // 14天过期
          })
        }
      }
      // 2. 设置cookie —— 端上与服务端交互
      const cookieValue = this.cookieMapArray.map(([shortKey, fullKey]) => {
        return `${shortKey}-${typeof config[shortKey] !== 'undefined' ? Number(config[shortKey]) : 1}`
      }).join('.')

      // 2.1 先删除 cookie （防止重复）
      this.delConfig()
      // cookieValue: s-1.r-0.u-1
      // 2.2 如果cookieValue没有关闭（0）
      cookieValue.includes('0') && setCookie({
        key: COOKIE_KEY,
        value: encodeURIComponent(cookieValue),
        end: 60 * 60 * 24 * 14, // 14天过期
        domain: this.getCurMainDomain(),
      })
    } catch (error) {
      console.log('设置cookieId或者personalizedSwitch失败!!!', error)
    }
  }
}

export const personalized = new Personalized()

/**
 * 设置 axios 请求头
 * @param {*} axiosReqConfig 
 * @returns 
 */
export const setAxiosHeader = (axiosReqConfig) => {
  if (typeof window == 'undefined') return axiosReqConfig
  try {
    const config = personalized.getConfig()
    if (config) {
      if (!axiosReqConfig.headers) axiosReqConfig.headers = {}
      axiosReqConfig.headers[HEADER_KEY] = encodeURIComponent(JSON.stringify(config))
    }
  } catch (error) {
    console.log('设置请求头失败!!!', error);
  }
  return axiosReqConfig
}

/**
 * 登出成功时候个性化推荐配置
 */
export const logoutPersonalSetting = () => {
  // 1. 删除 cookie
  personalized.delConfig()
  // 2. 看是否同步 localstorage 数据
  personalized.setConfigFromStorage()
}

/**
 * 登陆成功时候个性化推荐配置
 */
export const loginPersonalSetting = async () => {
  // 1. 删除 cookie
  personalized.delConfig()
  // 2. 请求 tsp 接口获取个性化配置
  const tspResult = await getPersonalizedSwitchStatus()
  // s-1.r-0.u-1
  // 3. 判断是否有结果
  if (tspResult) {
    const result = String(tspResult).split('.').reduce((acc, cur) => {
      const [key, value] = cur.split('-')
      acc[key] = Number(value)
      return acc
    }, {})
    // result = { s: 0, r: 0 }
    personalized.setConfig(result)
  }
  return Promise.resolve()
}

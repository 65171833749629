/* eslint-disable no-unused-vars */
/* eslint-disable @shein-aidc/common/notDirectUseCookie */
import UserKey from '../UserKey'
import { getLocalStorage, setLocalStorage } from '@shein/common-function'
import schttp from 'public/src/services/schttp'

class PrimeDisableCountry extends UserKey {
  static canHandle(key) {
    return key === 'primeDisableCountry'
  }

  async get () {
    // 先从客户端缓存里面取
    let primeDisableCountry = getLocalStorage('primeDisableCountry') || ''
    // 客户端取不到的话，再从服务端session里面取
    if (!primeDisableCountry) {
      primeDisableCountry = await this.getServiceCache()
    }
    if (primeDisableCountry) return primeDisableCountry
    return ''
  }

  getSync() {
    // 先从客户端缓存里面取
    const primeDisableCountry = getLocalStorage('primeDisableCountry') || ''
    if (primeDisableCountry) return primeDisableCountry
    return ''
  }

  async set(value) {
    const res = await this.setSerivceCache(value)
    if (res?.code == '0') {
      // 同步设置客户端缓存
      setLocalStorage({
        key: 'primeDisableCountry',
        value
      })
    }
  }

  // 设置服务端的缓存
  async setSerivceCache (value) {
    const data = {
      key: 'primeDisableCountry',
      actionType: 'Prime/setDisableCountry',
      value
    }
    const res = await schttp({
      url: '/api/common/userInfoManager/update',
      method: 'POST',
      data
    })
    return res
  }

  // 获取服务端的缓存
  async getServiceCache () {
    const res = await schttp({
      url: '/api/common/userInfoManager/get',
      method: 'GET',
      params: {
        key: 'primeDisableCountry',
        actionType: 'Prime/getDisableCountry'
      }
    })
    if (res?.code == '0') {
      if (res.data) {
        setLocalStorage({
          key: 'primeDisableCountry',
          value: res.data
        })
      } 
      return res?.data || ''
    }
  }
}

export default PrimeDisableCountry


const isFunction = _ => Object.prototype.toString.call(_) === `[object Function]`
const TYPE = ['newpage', 'curpage'] // 只支持此当前页和新页加载两种模式

/**
 * 
 * loadingConfig: {
 *   type: 'curpage',
 *   show: true
 *   config: {}
 * }
 * */ 
let instance = null

class Loading {
  constructor() {
    this.callbackFn = null
    this.currentRequestOption = null
  }

  use(fn) {
    this.callbackFn = isFunction(fn) ? fn : null
  }

  _validCallBack() {
    if (!this.callbackFn) {
      // console.warn('请求没有loading回调方法，请检查')
      return false
    }
    return true
  }

  // 上一个loading没有关闭，直接关闭掉开启新的loading
  _loadingTimeout() {
    if (this.currentRequestOption?.url) {
      this.close({ url: this.currentRequestOption.url })
    }
  }

  open({ url }, options = {}) {
    if (!this._validCallBack()) return
    const { show, type = 'curpage', config = {} } = options
    if (!show || !TYPE.includes(type)) return

    this._loadingTimeout()

    let loadingConfig = {}

    if (type === 'newpage') { // 新页加载
      loadingConfig = {
        show: true,
        type: 'newpage',
        containerCoverStyle: {
          position: 'fixed',
          top: '55%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          'transform-origin': '50% 50%',
        },
        ...config
      }
    } else {   // 当前页加载
      loadingConfig = {
        show: true,
        type: 'curpage',
        containerCoverStyle: {},
        curPageScrollFix: false, // 加载时滚动条是否固定
        ...config
      }
    }

    this.currentRequestOption = {
      url,
      loadingConfig,
    }
    this.callbackFn(loadingConfig)
  }

  close(data) {
    // 确保返回的接口错误时也应该是正确的格式
    if (this.currentRequestOption?.url === data?.config?.url && this.currentRequestOption?.loadingConfig) {
      this.callbackFn({
        ...this.currentRequestOption.loadingConfig,
        show: false
      })
      this.currentRequestOption = null
    }
  }
}

// 向外暴露三个函数，分别是打开、使用、关闭
function openLoading({ url }, options = {}) {
  if (!instance) {
    instance = new Loading()
  }
  return instance.open({ url }, options)
}

function useLoading(fn) {
  if (!instance) {
    instance = new Loading()
  }
  return instance.use(fn)
}

function closeLoading(data) {
  if (!instance) {
    instance = new Loading()
  }
  return instance.close(data)
}

export {
  openLoading,
  useLoading,
  closeLoading
}


